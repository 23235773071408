import React, { Component } from 'react';
// import {Navbar, Container, Nav, Form, Button, Col, Row, Table, Spinner, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Form, Button, Col, Row, Table, Spinner, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'
import swal from 'sweetalert'
import '../../styles.css'
import {config} from '../../../config/config'
import {exportar} from '../../../config/reports'
import {apis} from '../../../api/apis'
// import logo_petroamerica from '../../img/LogoPetroamerica.png'
import iconSave from '../../../img/iconSave.png'
import iconSearch from '../../../img/iconSearch.png'
// import iconUser from '../../img/iconUser.png'
import iconExcel from '../../../img/excel.png'


import Header from '../../header/header'

export default class ventaEESS extends Component {

  state={
    user: '',
    token: '',
    eePropias :[],
    puntoVentas: [],
    res:[],
    filtro:{
      fecha_i: moment().format('YYYY-MM-DD'),
      fecha_f: moment().format('YYYY-MM-DD'),
      razon_s: '',
      grifo:''
    },
    filtroValido:{
      fecha_i: moment().format('YYYY-MM-DD'),
      fecha_f: moment().format('YYYY-MM-DD'),
      razon_s: '',
      grifo:''
    },
    estadoFiltro:{
      razon_s: false,
      grifo:false,
    },
    estadoBotones:{
      enviar: true,
      excel:true
    },
    filtroNombre:{
      rz:'',
      grifo:''
    },
    estado_carga:false,
    estado_modal:false,
    estadoBotonGrabar: true,
    ingreComentario:{
      comentario:'',
      index: ''
    },
    buscador:''
  }

  async componentDidMount(){
    let user = ''
    let token = ''
    try{
      user  = localStorage.getItem('user')
      token = localStorage.getItem('token')
    }catch(err){
      if(!config.validarCookies()){ 
          config.cerrarSesion() 
          this.props.accederLogin(false)
      }
      return
    }
    
    this.setState({user: user, token: token})
    const eePropias = await apis.eePropio(user, token)
    if(eePropias.error){
      await swal("Mensaje",eePropias.error, "error")
      if(!config.validarCookies()){ 
          config.cerrarSesion() 
          this.props.accederLogin(false)
      }
      return
    }
    this.setState({eePropias:eePropias})
    
  }
  
  filtro = async (e) =>{
    e.preventDefault()
    this.actualizarEstado()
    let filtro = this.state.filtro
    if(filtro.razon_s === ''){
      this.setState({estadoFiltro: {...this.state.estadoFiltro, razon_s:true, grifo:false}})
      return
    }
    if(filtro.grifo === ''){
      this.setState({estadoFiltro: {...this.state.estadoFiltro, razon_s:false, grifo:true}})
      return
    }
    this.setState({estadoFiltro: { razon_s: false, grifo:false}, estado_carga: true})

    //let res_filtro = this.state.filtro
    const documento = await apis.documento(filtro.fecha_i, filtro.fecha_f, filtro.razon_s, filtro.grifo, this.state.user, this.state.token)
    if(documento.error){
      await swal("Mensaje",documento.error, "error")
      this.setState({estado_carga: false})
      if( !config.validarCookies()){ 
          config.cerrarSesion() 
          this.props.accederLogin(false)
      }
      return
    }

    documento.map(value => ((value.estadoComparativo = value.flg_eepp, value.observacion_eeppComparativo = value.observacion_eepp)))
    this.setState({res:documento, estado_carga:false, filtroValido:filtro})
    if(documento.length > 0){
      this.setState({estadoBotones:{enviar: false, excel:false}})
    }else{
      this.setState({estadoBotones:{enviar: true, excel:true}})
    }

  }

  ingresarDatos = async (text) => { 
    this.setState({ filtro: {...this.state.filtro, [text.target.name]: text.target.value} }) 
    if(text.target.name === 'razon_s' && text.target.value !== ''){
      const puntoVentas = await apis.puntosVenta(
        this.state.user, 
        text.target.value, 
        this.state.token
      )
      if(puntoVentas.error){
        await swal("Mensaje",puntoVentas.error, "error")
        if(!config.validarCookies()){ 
            config.cerrarSesion()
            this.props.accederLogin(false)
        }
        return
      }
      this.setState({puntoVentas: puntoVentas, filtro: {...this.state.filtro, grifo: ''}})
    }
  }

  validar_ingresarSegundaFecha = text =>{
    let fecha1 = moment(this.state.filtro.fecha_i);
    let fecha2 = moment(text.target.value);
    let dias = fecha2.diff(fecha1, 'days')

    if(dias < 0){
      this.setState({ filtro: {...this.state.filtro, [text.target.name]:  moment().format('YYYY-MM-DD')} }) 
      return
    }

    this.setState({ filtro: {...this.state.filtro, [text.target.name]: text.target.value} }) 

  }

  cambiarEstado = (res_index, res_value) => {
    this.state.res.map((value, index) => 
      value.pk === res_index 
        ? value.flg_eepp = res_value === null 
                              ? '1'
                              : res_value === '1' 
                                  ? '0' 
                                  : '1' 
        : null
    )
    // this.state.res.map((value, index) => 
    //   (value.pk === res_index) 
    //     ? value.flg_eepp =  (res_value === null || res_value === '0') 
    //                           ? '1'
    //                           : '0' 
    //     : null
    // )
    this.setState({res: this.state.res})
  }

  depurarLista = (lista) =>{
    const setObj = new Set();
    return lista.reduce((acc, documento) => {
        if (!setObj.has(documento.pk.toString())){
          setObj.add(documento.pk.toString(), documento)
          acc.push(documento)
        }
        return acc;
  },[]);
}

  enviarRegistrosAprobados = async () =>{
    this.setState({estadoBotonGrabar:false})
    let enviarDatosAprobador = []
    let aceptados = this.depurarLista(this.state.res)
    let aceptadosFinal = aceptados.filter(value => value.flg_eepp !== value.estadoComparativo || value.observacion_eepp !== value.observacion_eeppComparativo )
    console.log(aceptadosFinal)
     aceptadosFinal.forEach(element => {
       enviarDatosAprobador.push({pk: element.pk, flg_eepp: element.flg_eepp, observacion_eepp:element.observacion_eepp})
     });

     if(enviarDatosAprobador.length < 1){
       this.setState({estadoBotonGrabar:true})
       await swal("Mensaje","No se realizo ningun cambio para guardar los documentos.", "info")
       return
     }

     const peticion = await apis.documento_put(this.state.token, enviarDatosAprobador) 

     if(peticion.error){
       this.setState({estadoBotonGrabar:true, estadoBotones:{...this.state.estadoBotones, enviar: false}})
       await swal("Mensaje",peticion.error, "error")
       if(!config.validarCookies()){ 
           config.cerrarSesion() 
           this.props.accederLogin(false)
         }
       return
     }

     await swal("Mensaje","Registro enviado", "success")

     /*let res_filtro = this.state.filtro
     const documento = await apis.documento(res_filtro.fecha_i, res_filtro.fecha_f, res_filtro.razon_s, res_filtro.grifo, this.state.user, this.state.token)
     if(documento.error){
       this.setState({estadoBotonGrabar:true})
       await swal("Mensaje","Ocurrio un error al filtrar la información.", "error")
       if(!config.validarCookies()){ 
           config.cerrarSesion() 
           this.props.accederLogin(false)
       }
       return
     }

     documento.map(value => ((value.estadoComparativo = value.flg_eepp, value.observacion_eeppComparativo = value.observacion_eepp)))
     this.setState({res:documento, estado_carga:false, estadoBotonGrabar: true})*/
     this.setState({estado_carga:false, estadoBotonGrabar: true})

  }

/*MODAL DE COMENTARIO*/
  abrirEstadoModal = (index, comentario) =>{
    this.setState({ ingreComentario: {...this.state.ingreComentario, 'comentario': comentario, 'index': index}, estado_modal:true }) 
  }
  cerrarEstadoModal = () =>{
    this.setState({estado_modal:false})
  }

/*COMENTARIO*/
  ingresarComentario = text =>{
    this.setState({ ingreComentario: {...this.state.ingreComentario, [text.target.name]: text.target.value} }) 
  }
  guardarComentario = () =>{
    let comentario = this.state.ingreComentario
    this.state.res.map((value, index) => value.pk.toString() === comentario.index.toString() ? value.observacion_eepp = comentario.comentario.trim() : null)
    this.setState({res: this.state.res})
    this.cerrarEstadoModal()
  }

/*FILTRAR EN LA TABLA*/
  filtrarDatos = text =>{
    let texto = text.target.value
    this.setState({buscador: texto})
  }

  emitirReporteXlsx = () =>{
    let rs = ''
    let pv = ''
    let eeP = this.state.eePropias
    let puntoV = this.state.puntoVentas
    for (let index = 0; index < eeP.length; index++) {
      if(eeP[index].id_cliente === this.state.filtroValido.razon_s){
        rs = eeP[index].descripcion
        break
      }
    }
    for (let index = 0; index < puntoV.length; index++) {
      if(puntoV[index].id_punto_venta === this.state.filtroValido.grifo){
        pv = puntoV[index].descripcion
        break
      }
    }
    exportar.reporteXlsx_EESS(this.state.res, this.state.filtroValido, rs, pv)
    
  }

  actualizarEstado = () =>{
    this.setState({
      buscador:'',
      res:[],
      estadoFiltro:{
        razon_s: false,
        grifo:false,
      },
      estadoBotones:{
        enviar: true,
        excel:true
      },
      filtroNombre:{
        rz:'',
        grifo:''
      },
      ingreComentario:{
        comentario:'',
        index: ''
      },
      filtroValido:{
        fecha_i: moment().format('YYYY-MM-DD'),
        fecha_f: moment().format('YYYY-MM-DD'),
        razon_s: '',
        grifo:''
      },
      estado_carga:false,
      estado_modal:false,
      estadoBotonGrabar: true
    })
}

  render() {
    let repetidor = ''
    let enumerar = 0
    let estadoColor = true
    const {accederLogin} = this.props 
    return <div>
      {/* <Navbar className='reporte_navbar' expand="lg">
        <Container fluid>
          <Navbar.Brand href="#" style={{color:'white'}}><img src={logo_petroamerica} width="35" alt="logo" /> Reporte</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px'}} navbarScroll >
              <Nav.Link href="/petroamerica" onClick={()=>{config.cerrarSesion()}} style={{color:'white'}}>Salir</Nav.Link>
            </Nav>
            <Nav className="justify-content-end" activeKey="/">
              <Nav.Item>
                <Nav.Link href="#" style={{color:'white'}}><img src={iconUser} width="25" alt="icon"/> Usuario: {this.state.user} </Nav.Link>
              </Nav.Item>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      <Header nombre={this.state.user} accederLogin={accederLogin}/>      
      <div style={{position:'relative' ,margin:20, boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.15)'}}>
        <Form onSubmit={this.filtro} method="POST" style={{ display:'flex', flexDirection:'column'}} className="p-3" >
        <h6 >VALIDAR COMPRAS A HIDROMUNDO</h6>
          <Form.Group as={Row}>
            <Form.Group as={Row}  style={{maxWidth:'450px'}}>
              <Col sm="6">
                <Form.Text className="text-muted">Fecha Inicial</Form.Text>
                <Form.Control type="date" name='fecha_i' onChange={this.ingresarDatos} value={this.state.filtro.fecha_i} style={{fontSize:'0.9rem'}}/>
              </Col>
              <Col sm="6">
              <Form.Text className="text-muted">Fecha Final</Form.Text>
                <Form.Control type="date" name='fecha_f' onChange={this.validar_ingresarSegundaFecha} value={this.state.filtro.fecha_f} style={{fontSize:'0.9rem'}}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row}  style={{maxWidth:'450px'}}>
              <Col sm="6">
                <Form.Text className="text-muted">Cliente EEPP (Razón Social)</Form.Text>
                <Form.Select value={this.state.filtro.razon_s} isInvalid={this.state.estadoFiltro.razon_s} name='razon_s'  onChange={this.ingresarDatos} style={{fontSize:'0.9rem'}}>
                  <option value=''>Seleccionar...</option>
                  {
                    this.state.eePropias.map(value => 
                      <option key={value.id_cliente} value={value.id_cliente}>{value.descripcion}</option>
                    )
                  }
                </Form.Select>
              </Col>
              <Col sm="6">
                <Form.Text className="text-muted">Punto de Venta EEPP (Grifo)</Form.Text>  
                <Form.Select value={this.state.filtro.grifo} isInvalid={this.state.estadoFiltro.grifo} name='grifo'  onChange={this.ingresarDatos} style={{fontSize:'0.9rem'}}>
                  <option value=''>Seleccionar...</option>
                  {
                    this.state.puntoVentas.map(value => 
                      <option key={value.id_punto_venta} value={value.id_punto_venta}>{value.descripcion}</option>
                    )
                  }
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row}  style={{maxWidth:'120px', flexDirection:'column-reverse', marginLeft:'0px'}}>
              <Button variant="primary" type="submit" style={{maxWidth:'85px', padding:'0.2rem'}} ><img src={iconSearch} width="14" alt="icon"/> Buscar</Button>
            </Form.Group>
          
          </Form.Group>
        </Form>
      </div>

      <div style={{marginLeft:20, marginRight:20, marginTop:20, boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.15)', padding:20, display:'flex', flexDirection:'column'}}>
        
        <Row>
          <Col sm={6} className='mb-2'><Button variant="primary" type="button" disabled={this.state.estadoBotones.enviar} onClick={this.enviarRegistrosAprobados}>
            {
              this.state.estadoBotonGrabar ? 
              <>
                <img src={iconSave} width="14" alt="icon"/> Grabar 
              </>
                
              :
              <Spinner animation="border" variant="light" style={{width:'1em', height:'1em'}}/>
            }
          </Button></Col>
          <Col sm={6} className='mb-2'>
            <Row>
              <Col md="7"></Col>
              <Col md="5">
                <div className='d-flex' style={{justifyContent:'space-between'}}>
                  <Form.Control type="text" placeholder="buscar por documento.." onChange={this.filtrarDatos} style={{marginRight:'15px'}}/>
                  <OverlayTrigger
                    overlay={ <Tooltip id={`tooltip-1`}>Descargar</Tooltip> }
                  >
                    <Button variant="light" type="button" disabled={this.state.estadoBotones.excel} onClick={()=>this.emitirReporteXlsx()}><img src={iconExcel} alt="descargar" width={20}/></Button>
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div style={{overflowX:'scroll', width:'100%', maxHeight:'65vh'}}>
          <Table  bordered size="md"  style={{width:'100%', fontSize:'0.8rem'}}>
            <thead style={{position:'sticky', top:'0px', background:'white', boxShadow: '0px 0px 1px black'}}>
              <tr>
                <th >#</th>
                <th>Opciones</th>
                <th>Fecha</th>
                <th>Planta</th>
                <th style={{minWidth:'120px'}}>Cliente EEPP (Razón Social)</th>
                <th style={{minWidth:'150px'}}>Punto de Venta EEPP (Grifo)</th>
                <th>Documento</th>
                <th>Nro Scrop</th>
                <th style={{minWidth:'100px'}}>Total Doc S./</th>
                <th>Item</th>
                <th>Articulo</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.estado_carga ?
                  <tr>
                    <td  colSpan={16} align='center'>
                    <Spinner animation="grow"/>
                    </td>
                  </tr>
                : 
                this.state.res.filter(values => values.nro_documento.indexOf(this.state.buscador) !== -1).map((value, index)=>
                (
                
                repetidor !== value.pk?  

                <tr key={index} style={{background:estadoColor ? '#F5F5F5' : 'white'}}>
                  <td>{enumerar+=1}</td>
                  <td>
                    <div style={{display:'flex', justifyContent:'space-around'}}>
                      <Form.Check type="checkbox" checked={value.flg_eepp === '1' ? true : false} size="lg" onChange={()=>this.cambiarEstado(value.pk, value.flg_eepp)} style={{fontSize:'1rem'}}/> &nbsp;&nbsp;
                      <Button type="button" 
                        style={{
                          background: !value.observacion_eepp ? '#00B4D8' : '#FC4F4F', 
                          border: !value.observacion_eepp ? '1px solid #2193b0' : '1px solid #B33030'
                        }} 
                        size="sm" onClick={()=>this.abrirEstadoModal(value.pk, value.observacion_eepp)}>Comentario</Button>
                    </div>
                  </td>
                  <td>{moment(value.fecha).format("DD/MM/YYYY")}</td>
                  <td>{value.planta}</td>
                  <td>{value.cliente}</td>
                  <td>{value.punto_venta}</td>
                  <td>{value.id_tipo_doc.substring(0,1)}{value.serie}-{value.nro_documento}</td>
                  <td>{value.nro_scop}</td>
                  <td>{ new Intl.NumberFormat("en-us").format(value.total)}</td>
                  <td>{value.item}</td>
                  <td>{value.articulo}</td>
                  <td>{value.cantidad}</td>
                  <td hidden={true}>{repetidor = value.pk}</td>
                  <td hidden={true}>{estadoColor = !estadoColor}</td>
                </tr>

                :
              
                <tr key={index} style={{background:!estadoColor ? '#F5F5F5' : 'white'}}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{value.item}</td>
                  <td>{value.articulo}</td>
                  <td>{value.cantidad}</td>
                  <td hidden={true}>{repetidor = value.pk}</td>
                </tr> 
                ))
              }
            </tbody>
          </Table>
        </div>

      </div>

      <Modal
        show={this.state.estado_modal}
        onHide={this.cerrarEstadoModal}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Comentario</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control placeholder='Escriba un mensaje' as="textarea" rows={3} name='comentario' value={this.state.ingreComentario.comentario || ''} onChange={this.ingresarComentario}/>
          </Form.Group>
          <Button style={{background:'#2193b0', border:'1px solid #2193b0'}} type="button" onClick={this.guardarComentario}>Aceptar</Button>
        </Modal.Body>
      </Modal>
    </div>;
  }

}
