var url =  ""
let dominio = document.domain
if(dominio === "localhost" || dominio === "192.168.1.12"){
    url =  "http://192.168.1.12:8084/api"
}else{
    url =  "http://190.116.6.12:8084/api"
}
export const  apis = {
    login : async (data)=> {
        try{
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            const solicitud = await fetch(`${url}/login`, {method: 'POST', headers: headers, body:JSON.stringify(data)})
            const res = await solicitud.json()
            return res
        }catch(err){
            return {error: 'Ocurrió un error en el login.'}
        }
    },

    /*ESTACIONES PROPIAS*/
    eePropio: async (usuario, token) =>{
        try{
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', 'Bearer '+token);
            const solicitud = await fetch(`${url}/cliente/${usuario}`, {method: 'GET', headers: headers})
            const res = await solicitud.json()
            return res
        }catch(err){
            return {error: 'Ocurrió un error en la solicitud para la lista de Estaciones Propios.'}
        }
    },
    puntosVenta: async (usuario, id_cliente, token) =>{
        try{
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', 'Bearer '+token);
            const solicitud = await fetch(`${url}/PuntoVenta/${usuario}/${id_cliente}`, {method: 'GET', headers: headers})
            const res = await solicitud.json()
            return res
        }catch(err){
            return {error: 'Ocurrió un error en la solicitud para la lista de Punto de Venta.'}
        }
    },
    documento: async (fechaI, fechaF, id_cliente, ptoVenta, usuario, token) =>{
        try{
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', 'Bearer '+token);
            const solicitud = await fetch(`${url}/documento/${fechaI}/${fechaF}/${id_cliente}/${ptoVenta}/${usuario}`, {method: 'GET', headers: headers})
            const res = await solicitud.json()
            return res
        }catch(err){
            return {error: 'Ocurrió un error con la solicitud para la lista de Documentos.'}
        }
    },
    documento_put: async (token, data) =>{
        try{
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', 'Bearer '+token);
            const solicitud = await fetch(`${url}/documento`, {method: 'PUT', headers: headers, body: JSON.stringify(data)})
            return solicitud
        }catch(err){
            console.log(err)
            return {error: 'Ocurrió un error con la solicitud para guardar el registro.'}
        }
    },

    /*VOLUMETRICO*/
    planta: async (token, id_cia, id_use = '') =>{
        try{
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', 'Bearer '+token);
            const solicitud = await fetch(`${url}/planta/${id_cia}/${id_use}`, {method: 'GET', headers: headers})
            const res = await solicitud.json()
            return res
        }catch(err){
            return {error: 'Ocurrió un error en la solicitud para las plantas.'}
        }
    },
    proveedor: async (token, id_cia, fecha1, fecha2, id_tipo, id_planta, id_usuario) =>{
        try{
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', 'Bearer '+token);
            const solicitud = await fetch(`${url}/proveedor/${id_cia}/${fecha1}/${fecha2}/${id_tipo}/${id_planta}/${id_usuario}`, {method: 'GET', headers: headers})
            const res = await solicitud.json()
            return res
        }catch(err){
            return {error: 'Ocurrió un error en la solicitud para los proveedores.'}
        }
    },
    documentosVolumetrico: async (token, id_cia, fecha, fecha2, tipo, id_planta, id_proveedor, id_usuario) =>{
        try{
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', 'Bearer '+token);
            const solicitud = await fetch(`${url}/volumetrico/${id_cia}/${fecha}/${fecha2}/${tipo}/${id_planta}/${id_proveedor}/${id_usuario}`, {method: 'GET', headers: headers})
            const res = await solicitud.json()
            return res
        }catch(err){
            return {error: 'Ocurrió un error en la solicitud de los documentos del Volumetrico.'}
        }
    },
    grabarVolumetrico: async (token, data) =>{
        try{
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', 'Bearer '+token);
            const solicitud = await fetch(`${url}/volumetrico`, {method: 'PUT', headers: headers, body: JSON.stringify(data)})
            return solicitud
        }catch(err){
            console.log(err)
            return {error: 'Ocurrió un error con la solicitud para guardar el volumetrico.'}
        }
    }
}