import Cookies  from 'universal-cookie'
const cookies = new Cookies()

export const config = {
    cerrarSesionSalir : ()=> {
        //console.log(this.props)
        cookies.remove('status')
        localStorage.clear()
        /*
        //window.location.reload()
        this.props.accederLogin(false)*/
    },
    
    cerrarSesion : ()=> {
        cookies.remove('status')
        localStorage.clear()
        //window.location.reload()
        //this.props.accederLogin(false)
    },

    iniciarSesion : (token, user, tipoUsuario) =>{
        var fechaH = new Date();
        fechaH.setHours(fechaH.getHours()+1);
        localStorage.setItem('status', true) 
        localStorage.setItem('token',token)
        localStorage.setItem('user',user)
        //ENCRIPTAR TIPO DE USUARIO 
        let encript = btoa(tipoUsuario)
        localStorage.setItem('type',encript)
        cookies.set('status', true,{expires: new Date(fechaH)})
    },

    validarCookies : ()=>{
        if(cookies.get('status')){
            return true
        }
    }

    
}
