import React, { Component } from 'react'
import Header from '../header/header'
import {config} from '../../config/config'
import fondo from '../../img/fondo.png'

export default class inicio extends Component {
    state={
        user: ''
    }

    async componentDidMount(){
        let user = ''
        try{
            user  = localStorage.getItem('user')
        }catch(err){
            if(!config.validarCookies()){ 
                config.cerrarSesion() 
                this.props.accederLogin(false)
            }
            return
        }
        this.setState({user})
    }

    render() {
        const {accederLogin} = this.props 
        return (
            <>
                <Header nombre={this.state.user} accederLogin={accederLogin}/> 
                <img alt="logo" src={fondo} className="fondoLogo"/>
            </>
        )
    }
}
